<template>
  <div class="page-container medical-reports">
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Laudos</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Laudos</p>
            <p class="empty-subtitle">
              Nenhum laudo encontrado. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="text-center" width="130px">Data do exame</th>
              <th>Paciente</th>
              <th>Exame</th>
              <th>Profissional</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="text-center">{{ item.appointment.date | date }}</td>
              <td>{{ item.appointment.patient.name }}</td>
              <td>
                <span v-if="item.appointment.expenses.length > 0">
                  {{ item.appointment.expenses[0].name }}
                </span>
              </td>
              <td>{{ item.steps[0].professional.name }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-gray"
                        :disabled="printing"
                        :class="printing ? {loading: printing} : 'tooltip'"
                        @click="print(item.id)" data-tooltip="Imprimir">
                  <fa-icon :icon="['fal', 'print']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <st-pagination :total="data.total"
                       :limit="data.limit" @paginate="paginate"></st-pagination>
        <span>Total de registros:
          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: '/medical-reports',
      debounceTimeout: null,
      loading: false,
      printing: false,
      patientId: '',
      filter: {
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.patientId = this.$route.query.patientId;
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.patientId) {
        params.patientId = this.patientId;
      }

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    print(id) {
      this.printing = true;

      return this.$file
        .print(`/medical-reports/${id}/print`)
        .catch(() => {})
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .medical-reports {
  }
</style>
